<template>
    <div class="col-md-11 indentText">
        <div class="row row1" v-if="showDate">
            <div @click="isenabledMethod" class="hand">
                <span class="pull-left">
                   <!--<i class="fa fa-minus-circle" v-if="isenabled"></i>-->
                    <img class="" src="../../../assets/images/drop_down_minus_icon.svg" v-if="isenabled">
                   <img class="" src="../../../assets/images/dropdown_plus_icon.svg" v-else>
                    <!--<i class="fa fa-plus-circle"></i>-->
               </span>
                <h3>{{date2}}</h3>
            </div>
            <div class="time-div col2">
                <h3 class="pull-right" title="Hours:Minutes">
                    {{min_to_hour(date.date_total_time)}}</h3>
            </div>
        </div>
        <div class="row" v-if="isenabled">
            <task :key="index" :task="task" v-for="(task, index) in date.tasks" />
        </div>
    </div>
</template>

<script>

    import { min_to_hour } from '@/assets/js/utils'

    export default {
        name : "date",
        components : { task : () => import('@/components/reports/reportPage/tasks') },
        props : [ 'date', 'date2' ],
        data () {
            return {
                isenabled : true,
                showDate : true
            }
        },
        mounted () {
            if ( this.$store.state.custom.start_date === this.$store.state.custom.end_date ) {
                this.showDate = false
            }
            this.$root.$on ( 'reset_report_collapse', () => {
                this.isenabled = true
            } )
        },
        methods : {
            min_to_hour ( minutes ) {
                let time = min_to_hour ( minutes );
                let hrs = time.hrs;
                let mins = time.mins;
                if ( hrs <= 9 ) {

                    hrs = "0" + time.hrs
                }
                if ( mins <= 9 ) {
                    mins = "0" + time.mins
                }
                return hrs + ":" + mins
            },
            isenabledMethod () {
                this.isenabled = !this.isenabled;
            }
        }
    }
</script>

<style scoped>
    .row1 {
        border          : 0px;
        padding-right   : 0px !important;

        white-space     : nowrap;
        border-bottom   : 1px solid #D6E7FF;
        display         : flex;
        justify-content : space-between;
        align-items     : center;
    }

    .col2 {
        padding-right : 0px !important;
    }

    .time-div {
        padding      : 15px;
        padding-left : 0px !important;
    }

    h3 {
        padding-top   : 4px;
        margin-bottom : 0px;
        font-size     : medium;
        font-weight   : 400;
    }

    .hand {
        width                 : 78%;
        text-align            : left;


        -webkit-touch-callout : none; /* iOS Safari */
        -webkit-user-select   : none; /* Safari */
        -khtml-user-select    : none; /* Konqueror HTML */
        -moz-user-select      : none; /* Firefox */
        -ms-user-select       : none; /* Internet Explorer/Edge */
        user-select           : none;
        /* Non-prefixed version, currently
                                        supported by Chrome and Opera */
    }

    .hand h3 {
        white-space : pre-wrap;
    }

    .hand span {
        padding-right : 7px;
        padding-top   : 1px;
    }

    .hand span i {
        font-size : large;
    }

    img.img-icon {
        width  : 15px;
        height : 15px;
    }

    .col-md-6.hand {
        text-align : left;
    }

    .col-md-11.indentText {
        max-width : 97%;
        flex      : 97%;
    }

    .indentText {
        margin-left : 3%;
    }

    @media (max-width : 367px) {
        .row1 h3 {
            font-size : 14px;
        }
    }

    @media (max-width : 480px) {
        .time-div::before {
            content      : "";
            border-right : 1px solid #D6E7FF;
            padding      : 13px 0px;
            right        : 50px;
            top          : 15px;
            position     : absolute;
        }
    }

    @media (max-width : 320px) {
        .time-div::before {
            right : 39px;
        }
    }
</style>
